export default {
  baseUrl: 'https://spib5n0bl1.execute-api.us-east-1.amazonaws.com/api',
  //baseUrl: 'https://dcolhvf1lc.execute-api.us-east-1.amazonaws.com/api',
  //baseUrl: 'https://d82wr1wuhd.execute-api.us-east-1.amazonaws.com/api',
  joinagent: 'https://127.0.0.1:7777/join_session',
  leaveagent: 'https://127.0.0.1:7777/stop_session',
  login: '/login',
  getSession: '/get_session',
  getArenaSummary: '/get_arena_summary',
  joinSession: '/join_session',
  leaveSession: '/leave_session',
  generalGet: '/get',
  generalPut: '/put',
  acceptfriend: '/friends/accept_friend',
  rejectfriend: '/friends/reject_friend',
  blockfriend: '/friends/block',
  unblockfriend: '/friends/unblock',
  addfriend: '/friends/make_friend_request' 
}
