import React from "react";
import './App.css';
import Routermod from "./router";
import { Auth0Provider } from "@auth0/auth0-react";
import { Provider } from "react-redux";
import UserdetailsProviders from "./context/userdetailsContext";
import store from "./models/store";
function App() {
  return (
    <Auth0Provider
      domain="dev-aqp4a7nk.us.auth0.com"
      clientId="athir4SRt7Riey9kpnh0LHrVFhoSNanr"
      redirectUri={window.location.origin}
    >{/* <Provider store={store}> */}
        <UserdetailsProviders>
          <Routermod />
        </UserdetailsProviders>
      {/* </Provider> */}
    </Auth0Provider>
  )
}

export default App;
